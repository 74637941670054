import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';
import { Order, OrderItem, OrderType } from '../models/orders.model';
import { PlanItem } from '../models/plan.model';
import { License } from '../models/license.model';
import { UserService } from '../services/user.service';
import { RequestResponse } from '../models/request-status';
import { Company } from '../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public orderDialog = new Subject<PlanItem | null>();
  public orderExtendDialog = new Subject<License[]>();
  public orderAddedDialog = new Subject<any>();
  private apiServer: string;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId ? this.userService.selectedCompanyId : 'none' + ''
    );

    this.userService.companyChangeSubject.subscribe((company: Company) => {
      this.headers = this.headers.set(
        'x-company-id',
        company.companyId ?  company.companyId : 'none'
      );
    })
  }
  openOrderDialog(plan: PlanItem | null) {
    this.orderDialog.next(plan);
  }
  openExtendDialog(licenses: License[]) {
    this.orderExtendDialog.next(licenses);
  }
  openOrderAddedDialog() {
    this.orderAddedDialog.next(true);
  }
  fetchOrderHistory() {
    return this.http.get<RequestResponse>(`${this.apiServer}/orders`, {
      headers: this.headers,
    });
  }
  fetchOrderDetail(id: string) {
    return this.http.get<RequestResponse>(`${this.apiServer}/orders/${id}`, {
      headers: this.headers,
    });
  }
  createNewOrder(newOrder: Order) {
    return this.http.post<RequestResponse>(`${this.apiServer}/orders`, newOrder, {
      headers: this.headers,
    });
  }
  getOrderPrice(orderItems: OrderItem[], currency?: string, discountCode?: string | null) {
    
    let curencyParam = currency ? "?currency=" + currency : "";
    return this.http.post<RequestResponse>(`${this.apiServer}/orders/prices${curencyParam}`, { orderItems, discountCode }, {
      headers: this.headers,
    });
  }
  /*repeatPayOrder(newOrder: Order) {
    return this.http.post<RequestResponse>(`${this.apiServer}/orders`, newOrder, {
      headers: this.headers,
    });
  }*/
  getNewPayment(order: Order, force?: boolean) {
    let parameter = ""
    if (force) {
      parameter = "?force=1"
    }
    return this.http.post<RequestResponse>(`${this.apiServer}/orders/${order.id}/new-payment${parameter}`, {}, {
      headers: this.headers,
    });
  }
  checkDiscountCode(code: string) {
    return this.http.get<RequestResponse>(`${this.apiServer}/orders/discount-code/${code}`, {
      headers: this.headers,
    });
  }
  downloadInvoice(id: string) {
    this.headers.set(
      'Content-Type', 'application/pdf',
    );
    this.headers.set(
      'Accept', 'application/pdf',
    );
    console.log(this.headers);
    
    return this.http.get(`${this.apiServer}/orders/${id}/invoice?download=1`, {
      headers: this.headers,
      responseType: "blob",
    })
  }
}
