import { License, NewLicenseCalc } from './license.model';
import { Payment } from './payment.model';
import { PlanItem } from './plan.model';

export class Order {
  id?: string;
  orderNumber?: number;
  personISUID?: string;
  companyISUID?: string | null;
  purchaseDate: string;
  totalPrice: number;
  currency: string;
  orderState: OrderState;
  orderItems: OrderItem[];
  lang: string;
  returnUrl: string;
  stateHistory?: OrderStateInfo[];
  index?: number;
  payment?: Payment;
  vatRate: number;
  discountCode?: string | null;

  constructor() {
    this.purchaseDate = '';
    this.totalPrice = 0;
    this.currency = 'CZK';
    this.orderState = OrderState.CREATED;
    this.orderItems = [];
    this.lang = 'CS';
    this.returnUrl = '';
    this.vatRate = 0;
    this.companyISUID = null;
  }
}

export class OrderItem {
  orderType: OrderType;
  license: License;
  newLicense?: License | NewLicenseCalc;
  price: number;
  discount: number;
  priceSummary?: OrderPriceSummary;
  coupon?: OrderCoupon;
  _orderItemId: number;
  constructor(license: License) {
    this.orderType = OrderType.NEW;
    this.license = license;
    this.price = 0;
    this.discount = 0;
    this._orderItemId = 0;
  }
}
export type OrderStateInfo = {
  time: {
    $date: {
      $numberLong: string;
    }
  };
  state: string
}

export enum OrderType {
  NEW = 'NEW',
  EXTEND = 'EXTEND',
  CHANGE = 'CHANGE',
  DISCOUNT = 'DISCOUNT',
}

export enum OrderState {
  CREATED = 'CREATED',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  PAYMENT_METHOD_CHOSEN = 'PAYMENT_METHOD_CHOSEN',
  TIMEOUTED = 'TIMEOUTED',
  AUTHORIZED = 'AUTHORIZED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
}

export type OrderPrice = {
  basePrice: number;
  currency: string;
  orderItems: OrderItem[]
  totalPrice: number;
  vatRate: number;
  totalPriceWithVat: number;
  discountCoupon?: string;
  discountCode?: string;
}

export type OrderPriceSummary = {
  licnesePrice: number;
  servicePrice: number;
  basePrice: number;
  discountPer: number;
  totalPrice: number;
}

export type OrderCoupon = {
  planTypes: Array<string>;
  type: string;
  usageLimit: number;
  used: number;
  validFrom: string;
  validTo: string;
  value: any; // can be object or number;
  _id: string;
}


