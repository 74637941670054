import { PlayerCategory } from './player.model';

export type PlanFeature = {
  title: string;
  description: [];
  allowed: boolean;
};

export type PlanItem = {
  id: string;
  title: string;
  prices: any;
  currencyId: string;
  currencyTitle: string;
  unit: string;
  discountPayment: number;
  playerCategory: PlayerCategory;
  image: string;
  features: Array<PlanFeature>;
  planType: PlanType;
  countryRestrictions: {
    allowed:string[]
  };
  areaPrices:PlanAreaPrice[];
  detailHtml: string;
  vatRate: number;
  servicePrices: any
  shortDescription: string;
  boxCaption:string;
  planStatus:string;
  appendix : string;
};

export type PlanAreaPrice = {
  from: number;
  to: number;
  price: any;
};

export enum PlanType {
  SOLO = 'SOLO',
  OPUS = 'OPUS',
  MAGNUM = 'MAGNUM'
}

export enum PlanTemplate {
  FULL = 'FULL',
  COMPACT = 'COMPACT',
  SELECTION = 'SELECTION'
}