import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { CompanyService } from '../company/company.service';
import { UserService } from '../services/user.service';
import { CompanySelectDialogComponent } from '../company/company-select-dialog/company-select-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OrderStepperComponent } from '../orders/order-stepper/order-stepper.component';
import { OrderService } from '../orders/order.service';
import { Subscription } from 'rxjs';
import { CartService } from '../cart/cart.service';
import { PlanItem } from '../models/plan.model';
import { License } from '../models/license.model';
import { SupportService } from '../support/support.service';
import { SupportNewDeviceComponent } from '../support/support-new-device/support-new-device.component';
import { PlayersService } from '../players/players.service';
import { PlayerDetailComponent } from '../players/player-detail/player-detail.component';
import { PlansService } from '../plans/plans.service';
import { PlanDetailDialogComponent } from '../plans/plan-detail-dialog/plan-detail-dialog.component';
import { PlanBenefitsComponent } from '../plans/plan-benefits/plan-benefits.component';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent {
  isNavOpen: boolean = true;
  orderStepperSubscription: Subscription;
  orderExtendStepperSubscription: Subscription;
  supportNewDeviceSubscription: Subscription;
  playerDetailSubscription: Subscription;
  planDetailSubscription: Subscription;
  bendefitsSubscription: Subscription;
  menuBreakpoint = 1024;

  orderAddedSubscription: Subscription;
  public cartItemsCount = 0;
  public logoUrl: string | null = "";

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.isNavOpen = width >= this.menuBreakpoint;
  }
  
  constructor(
    public companyService: CompanyService,
    public userService: UserService,
    public orderService: OrderService,
    public supportService: SupportService,
    private palyerService: PlayersService,
    private planService: PlansService,
    private analyticsService: AnalyticsService,
    public dialog: MatDialog
  ) {
    this.logoUrl = this.companyService.logo;
   
    

    this.orderStepperSubscription = this.orderService.orderDialog.subscribe(
      (plan: PlanItem | null) => {
        this.openOrderStepper(plan);
      }
    );
    this.orderExtendStepperSubscription =
      this.orderService.orderExtendDialog.subscribe((licenses: License[]) => {
        this.openExtendOrderStepper(licenses);
      });
    this.orderAddedSubscription = this.orderService.orderAddedDialog.subscribe(
      () => {
        this.openOrderAdded();
      }
    );
    this.supportNewDeviceSubscription =
      this.supportService.newDeviceDialog.subscribe(() => {
        this.openSupportNewDevice();
      });

    this.playerDetailSubscription = this.palyerService.playerDetailDialog.subscribe((playerId) => {
      this.openPlayerDetail(playerId);
    });

    this.planDetailSubscription = this.planService.planDetailDialog.subscribe((plan) => {
      this.openPlanDetail(plan);
    });

    this.bendefitsSubscription = this.planService.planBenefitsDialog.subscribe(() => {
      this.openBenefitsDetail();
    });
  }

  ngOnInit() {
    this.onResize(window.innerWidth);

    if (!this.userService.isCompanySelected) {
      this.openCompanySelect();
    }
  }
  ngOnDestroy() {
    this.orderStepperSubscription.unsubscribe();
    this.orderAddedSubscription.unsubscribe();
    this.orderExtendStepperSubscription.unsubscribe();
    this.supportNewDeviceSubscription.unsubscribe();
    this.playerDetailSubscription.unsubscribe();
    this.planDetailSubscription.unsubscribe();
    this.bendefitsSubscription.unsubscribe();
  }
  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
  }

  openCompanySelect() {
    const dialogRef = this.dialog.open(CompanySelectDialogComponent, {
      disableClose: true,
      width: '400px',
      minHeight: '300px',
      maxWidth: '95vw',
    });
  }
  openOrderStepper(plan: PlanItem | null) {
    this.dialog.open(OrderStepperComponent, {
      disableClose: true,
      width: '1200px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      panelClass: 'order-stepper__dialog',
      data: { plan },
    });
  }
  openExtendOrderStepper(licenses: License[]) {
    this.dialog.open(OrderStepperComponent, {
      disableClose: true,
      width: '1200px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      panelClass: 'order-stepper__dialog',
      data: { licenses },
    });
  }
  openOrderAdded() {
    this.dialog.open(OrderStepperComponent, {
      disableClose: true,
      width: '500px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      panelClass: 'order-added__dialog',
    });
  }

  openSupportNewDevice() {
    this.dialog.open(SupportNewDeviceComponent, {
      disableClose: true,
      width: '700px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      panelClass: 'support-new-device__dialog',
    });
  }

  openPlayerDetail(playerId: string) {
    this.dialog.open(PlayerDetailComponent, {
      disableClose: true,
      width: '1000px',
      maxHeight: '100vh',
      maxWidth: '95vw',
      panelClass: 'player-detail__dialog',
      data: { playerId },
    });
  }

  openPlanDetail(plan: PlanItem) {
    this.dialog.open(PlanDetailDialogComponent, {
      disableClose: true,
      width: '80vw',
      maxHeight: '95vh',
      maxWidth: '95vw',
      panelClass: 'plan-detail-dialog',
      data: { plan },
    });
  }
  openBenefitsDetail() {
    this.dialog.open(PlanBenefitsComponent, {
      disableClose: true,
      width: '100%',
      maxHeight: '95vh',
      maxWidth: '800px',
      panelClass: 'plan-benefits-dialog'
    });
  }

  sendDataLayer(event: string) {
    this.analyticsService.addDataLayer(event)
  }
}
