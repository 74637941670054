
<div *ngIf="plan" class="plan-detail-dialog__body"  [ngClass]="'plan-detail-dialog__body--'+plan.planType">

  <div class="plan-detail-dialog__subtitle"  *ngIf="plan.planType == planTypeEnum.SOLO" i18n>zákadní</div>
  <div class="plan-detail-dialog__subtitle"  *ngIf="plan.planType == planTypeEnum.OPUS" i18n>doporučujeme</div>
  <div class="plan-detail-dialog__subtitle"  *ngIf="plan.planType == planTypeEnum.MAGNUM" i18n>individuální</div>

  <div class="plan-box" [ngClass]="'plan-box--'+plan.planType">
    <div v-if="props.template=='preview'" class="plan-box__container">
      <div  class="plan-box__content">
          <div>
              <div class="plan-box__header">
                  <div class="plan-box__header__subtitle">Tarif</div>
                  <div class="plan-box__header__title" >
                      <span class="plan-box__header__category" >dirigent</span>
                      <span class="plan-box__header__name">{{ this.plan.planType }}</span>
                  </div>
              </div>
              <div class="plan-box__short-description">
                  <p> {{ this.plan.shortDescription | languageMutation:locale }}</p>
              </div>
              <ul class="plan-box__features">
                  <li  *ngFor="let feature of this.plan.features">
                      <div *ngIf="isFeatureAllowed(feature)">
                          <svg *ngIf="this.plan.planType == 'SOLO'" class="plan-box__features__bullet-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M183.83 480a55.2 55.2 0 01-32.36-10.55A56.64 56.64 0 01128 423.58a50.26 50.26 0 0134.14-47.73L213 358.73a16.25 16.25 0 0011-15.49V92a32.1 32.1 0 0124.09-31.15l108.39-28.14A22 22 0 01384 54v57.75a32.09 32.09 0 01-24.2 31.19l-91.65 23.13A16.24 16.24 0 00256 181.91V424a48.22 48.22 0 01-32.78 45.81l-21.47 7.23a56 56 0 01-17.92 2.96z"></path></svg>
                          <svg *ngIf="this.plan.planType == 'OPUS'" class="plan-box__features__bullet-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M421.84 37.37a25.86 25.86 0 00-22.6-4.46L199.92 86.49A32.3 32.3 0 00176 118v226c0 6.74-4.36 12.56-11.11 14.83l-.12.05-52 18C92.88 383.53 80 402 80 423.91a55.54 55.54 0 0023.23 45.63A54.78 54.78 0 00135.34 480a55.82 55.82 0 0017.75-2.93l.38-.13 21.84-7.94A47.84 47.84 0 00208 423.91v-212c0-7.29 4.77-13.21 12.16-15.07l.21-.06L395 150.14a4 4 0 015 3.86v141.93c0 6.75-4.25 12.38-11.11 14.68l-.25.09-50.89 18.11A49.09 49.09 0 00304 375.92a55.67 55.67 0 0023.23 45.8 54.63 54.63 0 0049.88 7.35l.36-.12 21.84-7.95A47.83 47.83 0 00432 375.92V58a25.74 25.74 0 00-10.16-20.63z"></path></svg>
                          <svg *ngIf="this.plan.planType == 'MAGNUM'" class="plan-box__features__bullet-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M328.85 156.79a26.69 26.69 0 1018.88 7.81 26.6 26.6 0 00-18.88-7.81z"></path><path d="M477.44 50.06a.29.29 0 010-.09 20.4 20.4 0 00-15.13-15.3c-29.8-7.27-76.68.48-128.63 21.28-52.36 21-101.42 52-134.58 85.22A320.7 320.7 0 00169.55 175c-22.33-1-42 2.18-58.57 9.41-57.74 25.41-74.23 90.44-78.62 117.14a25 25 0 0027.19 29h.13l64.32-7.02c.08.82.17 1.57.24 2.26a34.36 34.36 0 009.9 20.72l31.39 31.41a34.27 34.27 0 0020.71 9.91l2.15.23-7 64.24v.13A25 25 0 00206 480a25.25 25.25 0 004.15-.34C237 475.34 302 459.05 327.34 401c7.17-16.46 10.34-36.05 9.45-58.34a314.78 314.78 0 0033.95-29.55c33.43-33.26 64.53-81.92 85.31-133.52 20.69-51.36 28.48-98.59 21.39-129.53zM370.38 224.94a58.77 58.77 0 110-83.07 58.3 58.3 0 010 83.07z"></path><path d="M161.93 386.44a16 16 0 00-11 2.67c-6.39 4.37-12.81 8.69-19.29 12.9-13.11 8.52-28.79-6.44-21-20l12.15-21a16 16 0 00-15.16-24.91A61.25 61.25 0 0072 353.56c-3.66 3.67-14.79 14.81-20.78 57.26A357.94 357.94 0 0048 447.59 16 16 0 0064 464h.4a359.87 359.87 0 0036.8-3.2c42.47-6 53.61-17.14 57.27-20.8a60.49 60.49 0 0017.39-35.74 16 16 0 00-13.93-17.82z"></path></svg>
                          {{ feature.title | languageMutation:locale }}
                      </div>
                  
                  </li>
              </ul>
          </div>
          <div class="plan-box__price">
              <span>od</span>
              <div class="plan-box__price__number">{{ plan.prices | currencySelection: companyCurrency }}</div>
              <span class="plan-box__price__interval">/ měsíc</span>
          </div>
          <div class="plan-box__actions">
              <button *ngIf="this.plan.planStatus == 'active'" class="plan-detail-dialog__order mat-mdc-raised-button--shadow  u__bg--primary-dirigent" mat-raised-button color="primary" (click)="openOrderStepper()" i18n>Předplatit službu</button>
              <div class="plan-box__actions__text" *ngIf="this.plan.planStatus == 'prepare'">připravujeme</div>
          </div>
      </div>
  </div>
</div>

  <div class="plan-detail-dialog__detail">
    <div class="plan-detail-dialog__detail__container">
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
      <div class="plan-detail-dialog__detail__content" [innerHTML]="plan.detailHtml | languageMutationHtml:locale">
      </div>
    
    <div class="plan-box__prices mt-10"  *ngIf="this.plan.planStatus=='prepare'">
      <h4 class="plan-box__message">Ceník pro vás připravujeme...</h4>
    </div>
    
    <div class="plan-box__prices mt-10" *ngIf="this.plan.planStatus=='contact'">
        <h4 class="plan-box__message">Pro nacenění vašich prodejen nás neváhejte kontaktovat...</h4>
    </div>

      <div  class="mt-10 plan-box__prices" *ngIf="this.plan.planStatus=='active'">
        <h4 class="plan-info__caption" i18n>Ceník podle plochy provozovny:</h4>

        <div class="plan-info__table">
          <div class="plan-info__table__header">
            <div class="plan-info__table__area" i18n>Plocha</div>
            <div class="plan-info__table__price" i18n>Cena</div>
            <div class="plan-info__table__price" i18n>Cena s DPH</div>
          </div>
          <div class="plan-info__table__header plan-info__table__header--second">
            <div class="plan-info__table__area" i18n>Plocha</div>
            <div class="plan-info__table__price" i18n>Cena</div>
            <div class="plan-info__table__price" i18n>Cena s DPH</div>
          </div>
        </div>
        <div  class="plan-info__table">
          <div *ngFor="let price of plan.areaPrices; let i = index" class="plan-info__table__row">
            <div class="plan-info__table__area" *ngIf=" i !== plan.areaPrices.length - 1" i18n>Plocha do {{ price.to}} m2</div>
            <div class="plan-info__table__area" *ngIf=" i == plan.areaPrices.length - 1" i18n>Plocha nad {{ price.to}} m2</div>
            <div class="plan-info__table__price"><strong >{{ price.price[companyCurrency] + plan.servicePrices[companyCurrency]  }} Kč</strong></div>
            <div class="plan-info__table__price"><strong >{{ calculateVatPriceNumber(price.price[companyCurrency] + plan.servicePrices[companyCurrency]) }}</strong></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>