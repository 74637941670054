<div class="cart-list" tabindex="0" *ngIf="order; else empty">
  <div class="cart-list__wrapper">
    <table mat-table [dataSource]="order.orderItems">

      <ng-container matColumnDef="orderType">
        <th mat-header-cell *matHeaderCellDef i18n>Typ</th>
        <td mat-cell *matCellDef="let item" style="width: 120px;">
          <span *ngIf="item.orderType == orderTypeEnum.EXTEND" i18n>Prodloužení</span>
          <span *ngIf="item.orderType == orderTypeEnum.NEW" i18n>Nová</span>
          <span *ngIf="item.orderType == orderTypeEnum.CHANGE" i18n>Změna</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="licenseName">
        <th mat-header-cell *matHeaderCellDef i18n>Licence</th>
        <td mat-cell *matCellDef="let item" i18n> {{ item.license.plan.title }} na {{ item.license.subscriptionLength |
          OrderMonths}} </td>
      </ng-container>

      <ng-container matColumnDef="playerName">
        <th mat-header-cell *matHeaderCellDef i18n>Název</th>
        <td mat-cell *matCellDef="let item"> {{ item.license.player.name}}</td>
      </ng-container>

      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef i18n>Plocha</th>
        <td mat-cell *matCellDef="let item"> {{ item.license.player.area}} m2</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef i18n>Začátek licence</th>
        <td mat-cell *matCellDef="let item"> {{item.license.startDate | date }} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef i18n>Konec licence</th>
        <td mat-cell *matCellDef="let item"> {{item.license.endDate | date }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right; width: 70px;"> </th>
        <td mat-cell *matCellDef="let item; let i = index" style="text-align: right; width: 70px;">
          <button (click)="removeItemFromCart(i)" color="primary" class="cart-list__action"
            mat-icon-button><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="priceWithVat">
        <th mat-header-cell *matHeaderCellDef i18n style="text-align: right;" i18n>Cena s DPH</th>
        <td mat-cell *matCellDef="let item" style="text-align: right;">
          <div class="cart-list__discount" *ngIf="item.priceSummary">
            <span style="text-decoration: line-through">{{ (item.priceSummary.basePrice * getVatMultiplayer(vatRate)) |
              currency:order.currency }}</span>
            <span class="cart-list__discount-title" *ngIf="item.priceSummary.discountPer" i18n> - {{
              item.priceSummary.discountPer }} % sleva</span>
          </div>
          <div class="flex gap-2 justify-end">
            <span> {{ (item.price * getVatMultiplayer(vatRate)) | currency:order.currency}} </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef i18n style="text-align: center; width:80px">DPH</th>
        <td mat-cell *matCellDef="let item" style="text-align: center; width:80px"> {{vatRate}} % </td>
      </ng-container>


      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef i18n style="text-align: right; " i18n>Cena bez DPH</th>
        <td mat-cell *matCellDef="let item" style="text-align: right; ">

          <div class="cart-list__discount" *ngIf="item.priceSummary">
            <span style="text-decoration: line-through">{{ item.priceSummary.basePrice | currency:order.currency }}</span>
            <span class="cart-list__discount-title" *ngIf="item.priceSummary.discountPer" i18n> - {{
              item.priceSummary.discountPer }} % sleva</span>
          </div>
          <div class="flex gap-2 justify-end">
            <span> {{item.price | currency:order.currency}} </span>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-divider></mat-divider>
  <div class="cart-list__discount-row" *ngIf="discountCoupon">
    <div></div>
    <div class="cart-list__discount-column">
      <span i18n>Sleva po uplatnění kódu: </span><span> {{ discountCoupon._id }}</span>
    </div>
    <div class="cart-list__discount-column cart-list__discount-type">
      <div *ngIf="discountCoupon.type=='PERCENT'">
        <span> {{ discountCoupon.value }}</span><span>%</span>
      </div>
      <div *ngIf="discountCoupon.type=='FIXED'">
        <span> -{{ discountCoupon.value[order.currency] | currency:order.currency }}</span>
      </div>
    </div>
  </div>
  <mat-divider *ngIf="discountCoupon"></mat-divider>
  <div class="cart-list__discount-code">
    <button *ngIf="!hasDiscountCode" style="width: 220px;" mat-stroked-button (click)="showDiscountCode()" i18n>Uplatnit slevový
      kód</button>
    <div [hidden]="!hasDiscountCode">
      <mat-form-field style="width: 250px;">
        <mat-label i18n>Slevový kód</mat-label>
        <input [formControl]="discountCodeForm" matInput i18n-placeholder placeholder="Slevový kód">
        <button *ngIf="discountCodeForm.value !=''" matSuffix mat-icon-button aria-label="Clear"
          (click)="resetDiscount()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="discountCodeForm.hasError('incorrect')" i18n>{{ couponErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
    <button [hidden]="!hasDiscountCode" style="width: 150px;" color="primary" mat-raised-button
      (click)="activateDiscount()" i18n>Aktivovat</button>
  </div>
  <div class="cart-list__summary mt-5 mr-20">
    <app-order-calculator (onPriceChanged)="onPriceChanged($event)" [order]="order"
      [discountCoupon]="discountCoupon"></app-order-calculator>
  </div>
  <div class="flex justify-between flex-1 mt-10">
    <button *ngIf="order.orderItems.length > 0" mat-raised-button (click)="clearCart()" i18n>Vyprázdnit košík</button>
    <button *ngIf="order.orderItems.length > 0" mat-raised-button (click)="payOrder()" color="primary"
      i18n>Zaplatit</button>
  </div>
</div>

<ng-template #empty>
  <div class="pictogram">
    <div>
      <svg data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <defs>
          <style>
            .opacity-25 {
              opacity: .25;
            }
            .opacity-0 {
              opacity: 0;
            }
            .opacity-50 {
              opacity: .5;
            }
          </style>
        </defs>
        <g id="Vrstva_1-2" data-name="Vrstva 1">
          <g>
            <g>
              <path class=" pictogram--primary" d="m396.08,208.78c-4.38-4.94-10.99-7.89-17.71-7.89h-27.15l15.46-41.83.12-.4c.76-3.01,1.14-5.57,1.14-7.61,0-9.24-6.13-17.9-15.26-21.55l-.1-.04c-5.69-2.1-11.93-1.83-17.57.75-5.66,2.59-9.93,7.15-12.03,12.85l-21.08,57.84h-92.3l-20.59-57.85c-4.29-11.66-17.79-17.88-29.51-13.62-9.05,2.87-15.31,11.46-15.96,21.9v.24c0,2.28,0,5.37,1.32,8.19l15.21,41.15h-25.42c-14.33,0-26.44,12.11-26.44,26.44,0,6.99,2.77,13.61,7.8,18.64,5,5,11.58,7.77,18.52,7.8l24.95,121.25c2.92,15.19,15.95,26.21,30.98,26.21h131.58c14.79,0,28.1-11.27,30.97-26.16l24.96-121.31c14.05-.35,25.81-12.3,25.81-26.42,0-6.94-2.74-13.53-7.71-18.55Zm-205.61,184.62c-11.28,0-21.08-8.36-23.3-19.92l-24.63-119.71h227.45l-24.64,119.76c-2.18,11.33-12.19,19.87-23.29,19.87h-131.58Zm186.88-147.46h-242.68c-4.9,0-9.56-1.96-13.11-5.51-3.55-3.55-5.51-8.21-5.51-13.11,0-10.09,8.53-18.61,18.62-18.61h28.3l3.87,10.75c2.09,5.69,6.36,10.24,12.03,12.83,3.14,1.43,6.46,2.15,9.76,2.15,2.63,0,5.25-.46,7.75-1.38,5.91-2.07,10.64-6.33,13.32-12.01,1.86-3.94,2.53-8.2,2.06-12.35h88.2c-.33,4.17.42,8.47,2.25,12.3,2.7,5.64,7.29,9.74,12.95,11.55,2.51.91,5.1,1.34,7.67,1.34,9.41,0,18.47-5.81,21.85-14.99l3.68-10.21h30.01c4.47,0,9.03,2.04,11.91,5.32l.18.19c3.55,3.55,5.51,8.21,5.51,13.11,0,10.09-8.53,18.61-18.62,18.61Zm-225.36-89.48c-.59-1.19-.61-3.16-.61-4.78.39-5.71,3.36-12.57,10.56-14.82l.18-.06c3.74-1.38,7.86-1.19,11.61.53,3.75,1.71,6.57,4.71,7.92,8.38l19.64,55.19,1.39,3.91.48,1.35c.31.84.53,1.69.68,2.56.52,3.01.11,6.13-1.25,9.01-1.77,3.76-4.91,6.59-8.88,7.98-3.74,1.38-7.86,1.19-11.61-.53-3.75-1.71-6.57-4.7-7.93-8.39l-2.91-8.07-1.41-3.91-.3-.84-1.13-3.07-16.28-44.04-.17-.39Zm158.25,44.42l20.09-55.15c1.37-3.71,4.19-6.7,7.94-8.42,3.74-1.71,7.84-1.9,11.56-.54,6.16,2.48,10.29,8.21,10.29,14.27,0,1.31-.3,3.26-.86,5.5l-16.38,44.35-.96,2.59-.48,1.33-1.41,3.91-2.71,7.53c-2.84,7.71-11.79,11.8-19.55,8.95l-.17-.06c-3.61-1.14-6.57-3.8-8.34-7.49-1.34-2.79-1.83-5.93-1.47-8.93.11-.9.29-1.79.56-2.66l.46-1.26,1.43-3.91Z"/>
              <path class=" pictogram--primary" d="m323.07,218.11c3.59,0,6.66-3.07,6.66-6.66-.14-.97-.43-1.9-.85-2.74-1.13-2.28-3.19-3.91-5.81-3.91s-4.95,1.64-6.03,3.91c-.4.84-.63,1.77-.63,2.74,0,3.59,3.07,6.66,6.66,6.66Z"/>
              <path class=" pictogram--primary" d="m188.93,218.11c3.59,0,6.66-3.07,6.66-6.66,0-.97-.23-1.9-.63-2.74-1.08-2.28-3.41-3.91-6.03-3.91s-4.95,1.64-6.03,3.91c-.4.84-.63,1.77-.63,2.74,0,3.59,3.07,6.66,6.66,6.66Z"/>
            </g>
            <g class="opacity-50">
              <path class="pictogram--primary" d="m388.61,185.35c2.55,0,4.61-2.06,4.61-4.61s-2.06-4.61-4.61-4.61-4.61,2.06-4.61,4.61,2.06,4.61,4.61,4.61Z"/>
              <path class="pictogram--primary" d="m388.61,313.85c-2.54,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.61-2.06,4.61-4.61-2.06-4.61-4.61-4.61Z"/>
              <path class="pictogram--primary" d="m265.73,175.62c-2.54,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
              <path class="pictogram--primary" d="m131.15,176.13c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
              <circle class="pictogram--primary" cx="204.11" cy="122.37" r="4.61"/>
              <path class="pictogram--primary" d="m129.54,326.65c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
              <path class="pictogram--primary" d="m288,126.98c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
              <path class="pictogram--primary" d="m229.89,146.43c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
            </g>
            <g>
              <path class="pictogram--primary" d="m302.22,296.95l-.02-.43c-.52-4.69-2.24-8.52-5.1-11.38-3.19-3.19-7.39-4.95-11.82-4.95-4.43,0-8.63,1.76-11.82,4.95l-14.38,14.38-14.38-14.38c-3.19-3.19-7.39-4.95-11.82-4.95h0c-4.43,0-8.63,1.76-11.82,4.95-2.87,2.87-4.58,6.7-5.11,11.38l-.02.43c0,4.44,1.73,8.42,5.13,11.82l14.38,14.38-14.56,14.56c-3.19,3.19-4.95,7.39-4.95,11.82s1.76,8.63,4.95,11.82,7.39,4.95,11.82,4.95,8.63-1.76,11.82-4.95l14.56-14.56,14.56,14.56c3.19,3.19,7.39,4.95,11.82,4.95s8.63-1.76,11.82-4.95c3.19-3.19,4.95-7.39,4.95-11.82s-1.76-8.63-4.95-11.82l-14.56-14.56,14.38-14.38c3.4-3.4,5.13-7.38,5.13-11.82Zm-10.66,6.29l-19.92,19.92,20.1,20.1c1.71,1.71,2.66,3.94,2.66,6.28s-.94,4.57-2.66,6.28-3.94,2.66-6.28,2.66-4.57-.94-6.28-2.66l-20.1-20.1-20.1,20.1c-1.71,1.71-3.94,2.66-6.28,2.66s-4.57-.94-6.28-2.66-2.66-3.94-2.66-6.28.94-4.57,2.66-6.28l20.1-20.1-19.92-19.92c-1.91-1.9-2.79-3.79-2.83-6.08.35-2.81,1.27-4.93,2.83-6.49,1.71-1.71,3.94-2.66,6.28-2.66h0c2.34,0,4.57.94,6.28,2.66l19.92,19.92,19.92-19.92c3.52-3.52,9.04-3.52,12.57,0,1.56,1.56,2.49,3.68,2.83,6.49-.05,2.29-.93,4.17-2.83,6.08Z"/>
              <path class="pictogram--primary" d="m340.91,286.37h-18.39c-2.16,0-3.91,1.75-3.91,3.91s1.75,3.91,3.91,3.91h18.39c2.16,0,3.91-1.75,3.91-3.91s-1.75-3.91-3.91-3.91Z"/>
              <path class="pictogram--primary" d="m337.9,326.81h-25.84c-2.16,0-3.91,1.75-3.91,3.91s1.75,3.91,3.91,3.91h25.84c2.16,0,3.91-1.75,3.91-3.91s-1.75-3.91-3.91-3.91Z"/>
              <path class="pictogram--primary" d="m204.16,314.55h-25.84c-2.16,0-3.91,1.75-3.91,3.91s1.75,3.91,3.91,3.91h25.84c2.16,0,3.91-1.75,3.91-3.91s-1.75-3.91-3.91-3.91Z"/>
              <path class="pictogram--primary" d="m202.74,355.82h-18.4c-2.16,0-3.91,1.75-3.91,3.91s1.75,3.91,3.91,3.91h18.4c2.16,0,3.91-1.75,3.91-3.91s-1.75-3.91-3.91-3.91Z"/>
            </g>
            <g class="opacity-25">
              <path class="pictogram--primary" d="m142.53,253.77l24.63,119.71c2.22,11.56,12.02,19.92,23.3,19.92h131.58c11.1,0,21.11-8.54,23.29-19.87l24.64-119.76h-227.45Zm60.21,109.87h-18.4c-2.16,0-3.91-1.75-3.91-3.91s1.75-3.91,3.91-3.91h18.4c2.16,0,3.91,1.75,3.91,3.91s-1.75,3.91-3.91,3.91Zm1.42-41.26h-25.84c-2.16,0-3.91-1.75-3.91-3.91s1.75-3.91,3.91-3.91h25.84c2.16,0,3.91,1.75,3.91,3.91s-1.75,3.91-3.91,3.91Zm93.11,15.34c3.19,3.19,4.95,7.39,4.95,11.82s-1.76,8.63-4.95,11.82c-3.19,3.19-7.39,4.95-11.82,4.95s-8.63-1.76-11.82-4.95l-14.56-14.56-14.56,14.56c-3.19,3.19-7.39,4.95-11.82,4.95s-8.63-1.76-11.82-4.95-4.95-7.39-4.95-11.82,1.76-8.63,4.95-11.82l14.56-14.56-14.38-14.38c-3.4-3.4-5.13-7.38-5.13-11.82l.02-.43c.52-4.69,2.24-8.52,5.11-11.38,3.19-3.19,7.39-4.95,11.82-4.95h0c4.43,0,8.63,1.76,11.82,4.95l14.38,14.38,14.38-14.38c3.19-3.19,7.39-4.95,11.82-4.95,4.43,0,8.63,1.76,11.82,4.95,2.87,2.87,4.58,6.7,5.1,11.38l.02.43c0,4.44-1.73,8.42-5.13,11.82l-14.38,14.38,14.56,14.56Zm40.63-3.08h-25.84c-2.16,0-3.91-1.75-3.91-3.91s1.75-3.91,3.91-3.91h25.84c2.16,0,3.91,1.75,3.91,3.91s-1.75,3.91-3.91,3.91Zm3.01-40.43h-18.39c-2.16,0-3.91-1.75-3.91-3.91s1.75-3.91,3.91-3.91h18.39c2.16,0,3.91,1.75,3.91,3.91s-1.75,3.91-3.91,3.91Z"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="pictogram__text">
      <h3 i18n>Váš košík je momentálně prázdný</h3>
      <p i18n>Vaše demo licence je časově omezená na 14 dní. Podívejte se na výhody, které získáte v případě předplacení našich služeb.</p>
    </div>
    <div class="pictogram__action">
      <button (click)="openBenefitsDetail()" mat-raised-button color="primary" class="mat-mdc-raised-button--shadow" i18n>Projít výhody</button>
    </div>
  </div>
</ng-template>