import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Order } from '../models/orders.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartContent = new BehaviorSubject<Order>(null!);
  private cartItemsCount = new BehaviorSubject<number>(0);

  cartContent$ = this.cartContent.asObservable();
  cartItemsCount$ = this.cartItemsCount.asObservable();
  private apiServer: string;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId + ''
    );

    let order = JSON.parse(localStorage.getItem('placedOrder')!);
    if (order) this.addToCart(order);
  }

  clearCart() {
    this.cartContent.next(null!);
    this.cartItemsCount.next(0);
    localStorage.removeItem('placedOrder');
  }
  addToCart(order: Order): void {
    if (this.cartContent.value) {
      /*order.orderItems = [
        ...this.cartContent.value.orderItems,
        ...order.orderItems,
      ];*/

      order.orderItems = this.cartContent.value.orderItems.concat(order.orderItems);
    }

    this.updateCart(order);
  }
  updateCart(order: Order) {
    if (!order) { 
      this.clearCart(); 
      return;
    }

    if (order.orderItems.length > 0) {
      this.cartContent.next(order);
      this.cartItemsCount.next(order.orderItems.length);
      localStorage.setItem('placedOrder', JSON.stringify(order));
    } else {
      this.clearCart();
    }
  }

 
}
