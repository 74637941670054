<form  [formGroup]="playerFormGroup" class="player-new flex flex-wrap" >
  <div class="u__form-row">
    <mat-form-field class="u__flex--fullwidth  flex-1" style="min-width: 150px;">
      <mat-label i18n>Název</mat-label>
      <input matInput i18n-placeholder  placeholder="Název" formControlName="name">
    </mat-form-field>
  </div>
  <div class="player-new__row u__form-row">
    <mat-form-field *ngIf="activePlanPrices" >
      <mat-label i18n>Plocha provozovny v m2</mat-label>
      <mat-select formControlName="area">
        <mat-option *ngFor="let areaPrice of activePlanPrices.areaPrices"   [value]="areaPrice.to" >{{ areaPrice.from }} - {{ areaPrice.to }} m2</mat-option>
        <mat-option  [value]="10001" >více než 10000 m2</mat-option>
      </mat-select>

      <!--input type="number" step="1"  matInput i18n-placeholder placeholder="Plocha provozovny" formControlName="area"-->
    </mat-form-field>
    <mat-form-field *ngIf="customArea" >
      <mat-label i18n>Upřesněte plochu v m2</mat-label>
      <input  type="number" min="10001" step="1"  matInput i18n-placeholder placeholder="Plocha provozovny" formControlName="customArea">
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label i18n>Typ provozovny</mat-label>
      <mat-select formControlName="storetypeId">
        <mat-option class="mt-2 mb-2" *ngFor="let storeType of storeTypes" [value]="storeType.storetypeId" >{{ storeType.title | languageMutation:locale }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="player-new__row  u__form-row">
    <mat-form-field class="u__flex--halfrow">
      <mat-label i18n>Adresa</mat-label>
      <input matInput i18n-placeholder placeholder="Adresa" formControlName="street">
    </mat-form-field>
    <mat-form-field class="u__flex--halfrow">
      <mat-label i18n>Město</mat-label>
      <input matInput i18n-placeholder placeholder="Město" formControlName="city">
    </mat-form-field>
    <mat-form-field class="u__form--150">
      <mat-label i18n>PSČ</mat-label>
      <input   matInput maxlength="5" i18n-placeholder placeholder="PSČ" formControlName="zip">
    </mat-form-field>
    <mat-form-field class="u__form--300">
      <mat-label i18n>Země</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let countryCode of plan?.countryRestrictions?.allowed" [value]="countryCode" >{{ getCounryTitle(countryCode) }}</mat-option>
        </mat-select>
    </mat-form-field>
  </div>  
</form>