import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Order, OrderType } from 'src/app/models/orders.model';

@Component({
  selector: 'app-order-recapitulation',
  templateUrl: './order-recapitulation.component.html',
  styleUrls: ['./order-recapitulation.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class OrderRecapitulationComponent implements OnChanges {
  @Input() order!: Order;
  orderTypeEnum: typeof OrderType = OrderType;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {

    if (changes['order']) {
      this.order = changes['order'].currentValue;
    }
  }
}
