import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'languageMutationHtml'
})
export class LanguageMutationHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(object: any, langCode: string, key?: string): string | SafeHtml{
    if (key) {

      if ( object[0].lang.split("-").length==1 ) {
        let founded = object.find((item: any) => item.lang == langCode.split("-")[1])[key];
        return this.sanitized.bypassSecurityTrustHtml(founded);
      }
      return this.sanitized.bypassSecurityTrustHtml(object.find((item: any) => item.lang == langCode)[key]);
    }
    return this.sanitized.bypassSecurityTrustHtml(object.find((item: any) => item.lang == langCode).value);

  }

}
