import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PlanItem } from 'src/app/models/plan.model';
import { Player, PlayerCategory } from 'src/app/models/player.model';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { PlayersService } from '../players.service';
import { RequestResponse } from 'src/app/models/request-status';
import { StoreType } from 'src/app/models/store.model';
import { PlansService } from 'src/app/plans/plans.service';

@Component({
  selector: 'app-player-new',
  templateUrl: './player-new.component.html',
  styleUrls: ['./player-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayerNewComponent implements OnChanges{
  @Output() playerUpdate = new EventEmitter<Player>();
  @Output() onValidation = new EventEmitter<any>();
  @Input() plan?: PlanItem | null = null;
  @Input() player?: Player | null = null;
  playerCategoryEnum: typeof PlayerCategory = PlayerCategory;
  storeTypes: StoreType[] = [];
  activePlanPrices: any = null;
  customArea: boolean = false;

  playerFormGroup: FormGroup;
  constructor(private fb: FormBuilder, private playersService: PlayersService, private plansService: PlansService , @Inject(LOCALE_ID) public locale: string) {
    if(!this.player) {
      this.player = new Player();
    }
    this.playerFormGroup = this.fb.group({
      name: new FormControl(this.player ? this.player.name : '', Validators.required),
      street: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      area: new FormControl(99, Validators.required),  
      customArea: new FormControl(10001, Validators.required),  
      country: new FormControl('', Validators.required),
      storetypeId: new FormControl(null, Validators.required),
    });
    this.playerFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }
  ngOnInit() {
    this.validateForm();
    this.fetchStoreTypes();
    this.playerFormGroup.markAllAsTouched();
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes["plan"]) {
      this.plan = changes["plan"].currentValue;
      if (this.player) {
        this.player.category = changes["plan"].currentValue;
      }
      this.playerFormGroup.get('country')?.setValue(this.plan?.countryRestrictions.allowed[0]);
      this.fetchPlans();

    }
    if(changes["player"]) {
      this.player = changes["player"].currentValue;
    }
  }
  get f() {
    return this.playerFormGroup.controls;
  }
  	
  fetchPlans() {
    let countryCode = CustomUtils.getCountryCode(this.locale);
   
    this.plansService.fetchPlans(countryCode, "unknown").subscribe({
      next: (data) => {
        this.activePlanPrices = data.data.plans.find((planPrice: PlanItem) => planPrice.planType == this.plan?.planType);
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
  
  fetchStoreTypes() {
    this.playersService.getStoreTypes().subscribe({
      next: (data: RequestResponse) => {
        if(data.data.storetypes) {
          this.storeTypes = data.data.storetypes;
        }
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    })
  }
  updateDataModel(value: any) {
    if (value.area) {
      if(value.area > 10000) {
          this.customArea = true;
      } else {
        this.customArea = false;
      }

      if (value.customArea > 10000 && this.customArea) {
        value.area = value.customArea;
      }
    }
    
    this.player = Object.assign(this.player!, value);
    if (this.player) this.playerUpdate.emit(this.player);
    this.validateForm();
  }

  validateForm() {
    this.onValidation.emit(this.playerFormGroup.valid);
  }
  getCounryTitle(code: string) {
    return CustomUtils.getCountryTitleByCode(code);
  }
}
